<template>
<v-app>
    <v-navigation-drawer color="grey lighten-5" v-model="drawer" :clipped="$vuetify.breakpoint.lgAndUp" app>
        <v-list dense>
            <template>
                <v-list-item-group v-model="item">

                  <v-list-item>
                      <v-list-item-avatar>
                          <img src="https://boosmart.com/wp-content/uploads/2022/01/boosmart-logo.svg" alt="Brand">
                      </v-list-item-avatar>
                      <v-list-item-content>
                          <v-list-item-title>{{ localUser.name }} {{ localUser.surname }}</v-list-item-title>
                          <v-list-item-subtitle v-if="localUser.role">{{ localUser.role }}</v-list-item-subtitle>
                      </v-list-item-content>
                  </v-list-item>

                  <v-divider></v-divider>
                    <router-link style="text-decoration: none" :to="{ name: item.route }" :key="item.text" v-for="item in items">
                        <v-list-item :key="item.text" link>
                            <v-list-item-action>
                                <v-icon>{{ item.icon }}</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ item.text }}
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </router-link>
                </v-list-item-group>
            </template>
        </v-list>

    </v-navigation-drawer>

    <v-app-bar :clipped-left="$vuetify.breakpoint.lgAndUp" app color="primary" dark>
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        <v-toolbar-title style="width: 202px" class="ml-0 pl-4">
          <span class="hidden-sm-and-down">BOOSMART XML</span>
        </v-toolbar-title>
        <v-text-field flat solo-inverted hide-details prepend-inner-icon="mdi-magnify" label="Search" class="hidden-sm-and-down"></v-text-field>
        <v-spacer></v-spacer>

        <v-menu v-model="accountMenu" :close-on-content-click="false" :nudge-width="100" bottom offset-y>
            <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-account-circle</v-icon>
                </v-btn>
            </template>

            <v-card>
                <v-list>
                    <v-list-item>
                        <v-list-item-avatar>
                            <img src="https://boosmart.com/wp-content/uploads/2022/01/boosmart-logo.svg" alt="Brand">
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title>{{ localUser.name }} {{ localUser.surname }}</v-list-item-title>
                            <v-list-item-subtitle v-if="localUser.role">{{ localUser.role }}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn text block color="primary" @click="logout()">
                        <v-icon left>mdi-logout</v-icon>
                        Logout
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-menu>

    </v-app-bar>
    <v-main style="background: #fafafa">
        <router-view />
    </v-main>

    <v-card class="mt-5 pt-5">
        <v-footer absolute class="font-weight-medium">
            <v-col class="text-center" cols="12">
                <div style="font-size: 12px">&copy; {{ new Date().getFullYear() }} - Made with ❤️ by Boosmart & Tasarlab </div>
            </v-col>
        </v-footer>
    </v-card>
</v-app>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'
import router from '@/router';
import {
    mapGetters,
    mapState,
    mapActions
} from 'vuex';
import moment from 'moment';

export default {
    name: 'Home',
    components: {},
    data: () => ({
        serviceURL: 'http://localhost:3031',
        //serviceURL: 'http://161.35.73.115:3031',
        item: -1,
        accountMenu: false,
        companySelectMenu: false,
        selectedMenu: null,
        dialog: false,
        drawer: null,
        timestamp: '',
        items: [
            {
                icon: 'mdi-xml',
                text: 'Feed Management',
                route: 'FeedSingle',
            },
            {
                icon: 'mdi-account-edit-outline',
                text: 'Users',
                route: 'Users'
            },
            {
                icon: 'mdi-clipboard-text-clock-outline',
                text: 'Logs',
                route: 'logs'
            },
            {
                icon: 'mdi-clipboard-text-clock-outline',
                text: 'Queue',
                route: 'queue'
            },
            {
                icon: 'mdi-cog-outline',
                text: 'Settings',
                route: 'Settings'
            },
            {
                icon: 'mdi-help-circle-outline',
                text: 'Support',
                route: 'Support'
            },
        ],
        localUser: {},
        companyId: '',
    }),
    created() {
        this.localUser = this.user.user;
    },
    watch: {

    },
    computed: {
        ...mapState('auth', {
            user: 'payload'
        }),
    },
    methods: {
        logout() {
            this.$store.dispatch('auth/logout').then((e) => {
                if (e) {
                    router.go('/login');
                }
            })
        },
        getNow() {
            axios({
                method: 'GET',
                url: this.serviceURL + '/now',
            }).then(res => {
                this.timestamp = res.data;
            })
        },
        closeEdit() {
            this.isEdit = false;
        },
    }
}
</script>
